import './defines';
import ButtonComponent from 'components/button';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'LogoColorLimitExceededBodyView',
	components: {
		ButtonComponent,
	},
})
export default class LogoColorLimitExceededBodyView extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the maximum number of colors allowed in the logo',
		type: Number,
		required: true,
	})
	public readonly limit!: number;

	@Prop({
		description: 'Defines the number of colors in the logo',
		type: Number,
		required: true,
	})
	public readonly value!: number;

	protected onEditColorsClick(): void {
		this.$emit('edit-colors');
	}
}
