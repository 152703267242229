import './defines';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'ExceededColorSelectionView',
})
export default class ExceededColorSelectionView extends Vue.extend(Template) {
	@Prop({
		description: 'Defines the maximum number of colors allowed in the logo',
		type: Number,
		required: true,
	})
	public readonly value!: number;

	protected imageUrlExceededColorSelection = '';

	protected imageUrlColorLimit = '';

	protected created(): void {
		// @ts-ignore
		import(/* webpackMode: "eager" */ '@root/img/colors/exceeded-color-selection.jpg')
			.then((imageUrl) => {
				this.imageUrlExceededColorSelection = imageUrl.default;
			});
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		if (this.value) {
			let colorLimitFilename = 'full';

			if (this.value === 1) {
				colorLimitFilename = 'one';
			} else if (this.value === 2) {
				colorLimitFilename = 'two';
			} else if (this.value === 3) {
				colorLimitFilename = 'three';
			} else if (this.value === 4) {
				colorLimitFilename = 'four';
			}

			import(/* webpackMode: "eager" */ `@root/img/colors/${colorLimitFilename}.svg`)
				.then((imageUrl) => {
					this.imageUrlColorLimit = imageUrl.default;
				});
		}
	}
}
