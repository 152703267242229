import { PhotoFilter } from 'interfaces/app';
import _ from 'underscore';
import {
	VuexModule,
	Module,
	Mutation,
	Action,
} from 'vuex-module-decorators';

const parseSetting = (
	value: any,
	boolean: boolean,
	number: boolean,
) => {
	let val;

	if (boolean) {
		val = !!+value;
	} else if (number) {
		val = parseInt(
			value,
			10,
		);
	} else if (
		value
		&& value[0] == '['
	) {
		if (value[1] == '\'') {
			const arrayString = value.substring(
				2,
				value.length - 2,
			);
			val = arrayString.split('\',\'');
		} else {
			const arrayInt = value.substring(
				1,
				value.length - 1,
			);
			val = [];
			_.each(
				arrayInt.split(','),
				(int: string) => {
					val.push(parseInt(
						int,
						10,
					));
				},
			);
		}
	} else {
		val = value;
	}

	return val;
};

type AppModule = 'account' | 'cart' | 'crossSell' | 'webshop' | 'store';

@Module({ namespaced: true, name: 'config' })
export default class Config extends VuexModule {
	'adyen.sdkVersion' = '1.9.7';

	'analytics.amplitude.debug' = false;

	'analytics.amplitude.enabled' = false;

	'analytics.amplitude.externalUserId' = false;

	'analytics.amplitude.id': string | null = null;

	'analytics.gtm.debug' = false;

	'analytics.gtm.ecommerce' = false;

	'analytics.gtm.email' = false;

	'analytics.gtm.enabled' = false;

	'analytics.gtm.events' = false;

	'analytics.gtm.externalUserId' = false;

	'analytics.gtm.id': string | null = null;

	// This is a special property for the Hema label as we need to use a different GTM id for Belgium
	// TODO We need to create a more universal way of setting properties for specific countries
	'analytics.gtm.id-be': string | null = null;

	'analytics.moengage.enabled' = false;

	'analytics.moengage.id': string | null = null;

	'analytics.moengage.cluster' = 'DC_2';

	'analytics.moengage.debug' = false;

	'analytics.moengage.externalUserId' = false;

	'analytics.nativeApp.enabled' = true;

	'analytics.nativeApp.externalUserId' = false;

	'analytics.segment.debug' = false;

	'analytics.segment.enabled' = false;

	'analytics.segment.externalUserId' = false;

	'analytics.segment.id': string | null = null;

	'app.name': string | null = null;

	'app.modules': AppModule[] = [];

	'appSync.url': string | null = null;

	'appDataVersion': string | null = null;

	'auth.internal' = true;

	'auth.loginIcon' = 'far fa-sign-in fa-2x';

	'auth.showStart' = true;

	'auth.showEnd' = false;

	'Bugsnag': string | null = null;

	'createModule.autoForwardThemes' = true;

	'createModule.showFullPricing' = false;

	'createModule.historyBack' = false;

	'cropModule.threshold' = 0.98;

	'debug.remotejs': string | null = null;

	'editor.frameColors.locked.fill' = '#E4286B';

	'editor.frameColors.locked.stroke' = '#E4286B';

	'editor.frameColors.locked.line' = '#FFFFFF';

	'editor.frameColors.unlocked.fill' = '#00CCCC';

	'editor.frameColors.unlocked.stroke' = '#00CCCC';

	'editor.frameColors.unlocked.line' = '#FFFFFF';

	'features.askNativeAppReview' = true;

	'features.crossSell' = true;

	'features.countryDetection' = true;

	'features.editor.zoom' = true;

	'features.imageEffects': PhotoFilter[] = ['boost', 'grey', 'sepia', 'negate'];

	'features.imageMasks' = true;

	'features.offerProjectLink' = true;

	'features.pms' = false;

	'features.qrUpload' = true;

	'features.smartystreets': string | null = null;

	'features.smartZoom' = false;

	'features.upsell' = false;

	'features.vectorization' = false;

	'features.webPush' = false;

	'flagsmith.enabled' = false;

	'flagsmith.id': string | null = null;

	'header.account' = true;

	'header.menu.categories' = true;

	'header.menu.appReview' = false;

	'header.menu.show' = true;

	'header.status' = true;

	// Temporary flag to enable the HelloPrint legacy integration for photo products
	'helloprint.legacy' = false;

	'links.legal': string | null = null;

	'links.extraCheckmark': string | null = null;

	'links.referral': string | null = null;

	'links.blog': string | null = null;

	'links.loyalty': string | null = 'https://printiki.typeform.com/to/KsLbkH?email=<%= email %>';

	'links.loyaltyInfo': string | null = 'https://www.printiki.com/vip-program';

	'LogRocket.id': string | null = null;

	'LogRocket.enabled' = true;

	'LogRocket.externalUserId' = false;

	'LogRocket.filter.url': string | null = null;

	'pageMargin.warningThreshold' = 0.95;

	'pageSize.bestFit.precision' = 0.97;

	'partnerID' = 0;

	'photoPreviewSize' = 1500;

	'photoPreviewSizeLocal' = 1500;

	'photoScalingBaseUrl': string | null = null;

	'projectImageBaseUrl': string | null = null;

	'Raygun': string | null = null;

	'share': string[] = [];

	'sse': string | null = null;

	'support.chat': 'zopim' | 'salesforce' = 'zopim';

	'support.email': string | null = null;

	'support.zopim.apikey': string | null = null;

	'support.zopim.tag': string | null = null;

	'textEncodingSupport': string[] = ['latin'];

	'tours.cardgame_overview.show' = false;

	'tours.editor_module.show' = false;

	'tours.photoframebox_overview.show' = false;

	'upload.fileSizeLimit' = '20MB'; // Maximum filesize the user can upload

	'upload.accelerate' = false;

	'upload.eps' = false;

	'upload.heic' = true;

	'upload.pdf' = false;

	'upload.svg' = false;

	'upscaling.maxOutput' = 128 * 1000000; // 128 megapixels

	'upscaling.recommendedMaxScale' = 8;

	'userAccount.requiredFields': string[] = [];

	'userAccount.route' = 'user';

	'voucherBox' = true; // show the voucher box in the webshop

	'webfont.timeout' = 100; // Delay in setting webfont loaded (fix for iOS)

	'webshop.extraCheckbox' = true;

	// For integration with external webshops, this is path we POST to that will perform the redirect to the webshop
	'webshop.external.endpoint': string | null = null;

	'webshop.external.formPost' = true;

	'webshop.expressShipping' = false;

	'webshop.register' = false; // Is a registered user account required?

	[key: string]: any;

	get hasAccountModule() {
		return this['app.modules'].indexOf('account') >= 0;
	}

	get hasCartModule() {
		return this['app.modules'].indexOf('cart') >= 0;
	}

	get hasCrossSellModule() {
		return this['app.modules'].indexOf('crossSell') >= 0;
	}

	get hasStoreModule() {
		return this['app.modules'].indexOf('store') >= 0;
	}

	get hasWebshopModule() {
		return this['app.modules'].indexOf('webshop') >= 0;
	}

	get labelTagName() {
		return this['app.name'];
	}

	@Mutation
	private _applySetting(o: {key: string; value: string}) {
		this[o.key] = o.value;
	}

	@Action({ rawError: true })
	public addModels(
		data: {
			[key: string]: any;
		} = {},
	) {
		const { commit } = this.context;

		// Add settings
		const pairs = _.pairs(data);
		pairs.forEach(([key, value]) => {
			const boolean = typeof this[key] === 'boolean';
			const number = typeof this[key] === 'number';
			const setting = parseSetting(
				value,
				boolean,
				number,
			);
			commit(
				'_applySetting',
				{
					key,
					value: setting,
				},
			);
		});
	}
}
