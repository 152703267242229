import './defines';
import ButtonComponent from 'components/button';
import ButtonToggleComponent from 'components/button-toggle';
import ButtonToggleGroupComponent from 'components/button-toggle-group';
import {
	ButtonToggleComponentVariant,
	EditorPreviewPageObjectModels,
	FullOfferingModels,
	FullOfferingOptionIntersectModel,
	FullOfferingOptionVirtualIntersectModel,
	OfferingFrameModel,
	OfferingOptionsToolbarCurrencyModel,
	OfferingOptionsToolbarFilterTag,
	OfferingOptionsToolbarFilterTags,
	OfferingOptionsToolbarFullOfferingModel,
	OfferingOptionsToolbarFullOfferingModels,
	OfferingOptionsToolbarFullOfferingOptionModel,
	OfferingOptionsToolbarProductOptionModel,
	OfferingOptionsToolbarProductOptionValueModel,
	OfferingOptionsToolbarProductOptionValueModels,
	OfferingOptionsToolbarScrollDirection,
} from 'interfaces/app';
import {
	Model2DModel,
} from 'interfaces/database';
import { PageModel } from 'interfaces/project';
import { AppDataModule } from 'store';
import { mobile as mobileTools } from 'tools';
import { dom as domUtils } from 'utils';
import EditorPreview2DView from 'views/editor-preview-2d';
import PriceView from 'views/price';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'OfferingOptionsToolbar',
	components: {
		ButtonComponent,
		ButtonToggleComponent,
		ButtonToggleGroupComponent,
		EditorPreview2DView,
		PriceView,
	},
})
export default class OfferingOptionsToolbar extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the full offering model active for the current project',
			required: true,
			schema: 'OfferingOptionsToolbarFullOfferingModel',
			type: Object,
		},
	)
	public readonly value!: OfferingOptionsToolbarFullOfferingModel;

	@Prop({
		default: undefined,
		description: "Defines the bleed margin for the current project to be used to show the product's preview",
		type: Number,
	})
	public readonly bleedMargin?: number;

	@Prop({
		required: true,
		schema: 'OfferingOptionsToolbarCurrencyModel',
		type: Object,
	})
	public readonly currencyModel!: OfferingOptionsToolbarCurrencyModel;

	@Prop({
		default: undefined,
		description: 'Defines the filter tags to filter out the offering models, the order of the tags will define the order the tags will be shown in the toolbar',
		schema: 'OfferingOptionsToolbarFilterTags',
		type: Array,
	})
	public readonly filterTags?: OfferingOptionsToolbarFilterTags;

	@Prop({
		description: 'Defines the full offering models available for the current project',
		required: true,
		schema: 'OfferingOptionsToolbarFullOfferingModels',
		type: Array,
	})
	public readonly fullOfferingModels!: OfferingOptionsToolbarFullOfferingModels;

	@Prop({
		default: undefined,
		description: "Defines the page model to be used to show the product's preview",
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel?: PageModel;

	@Prop({
		default: undefined,
		description: "Defines the page objects to be used to show the product's preview",
		schema: 'EditorPreviewPageObjectModels',
		type: Array,
	})
	public readonly pageObjects?: EditorPreviewPageObjectModels;

	@Prop({
		default: undefined,
		description: "Defines the 2D preview model to be use to show the product's preview",
		schema: 'Model2DModel',
		type: Object,
	})
	public readonly previewModel?: Model2DModel;

	protected get isProductOptionValueInverseLayout(): (offeringOption: OfferingOptionsToolbarFullOfferingOptionModel) => boolean {
		return (offeringOption) => offeringOption.tag === 'frame';
	}

	private get isVirtualOffering(): boolean {
		if (this.internalValue) {
			return !!AppDataModule.findOffering({
				flexgroupid: this.internalValue.flexgroupid,
				groupid: this.internalValue.groupid,
				instock: 1,
				virtual: 1,
			}).length;
		}

		return false;
	}

	protected get offeringFrameModel(): OfferingFrameModel | undefined {
		if (!this.internalValue) {
			return undefined;
		}

		return AppDataModule.getOfferingFrame(this.internalValue.id);
	}

	protected get offeringModelPrice(): number {
		if (!this.currencyModel) {
			return 0;
		}

		const pricingModel = AppDataModule.findPricingWhere({
			offeringid: this.internalValue.id,
			currency: this.currencyModel.id,
		});

		return pricingModel?.price_base || 0;
	}

	protected get offeringOptionClasses(): (offeringOption: OfferingOptionsToolbarFullOfferingOptionModel) => Record<string, boolean> {
		return (offeringOption) => {
			const offeringsWithImages = this.productOptionValuesToShow(offeringOption).every((productOptionValue) => !!productOptionValue.image);

			return {
				[`offering-option-value-${offeringOption.name}`]: true,
				'offering-options-with-images': offeringsWithImages,
			};
		};
	}

	protected get offeringOptionStyles(): (offeringOption: OfferingOptionsToolbarFullOfferingOptionModel) => (Partial<CSSStyleDeclaration> & Record<string, string>) {
		return (offeringOption) => {
			const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

			if (this.$el) {
				const offeringOptionButtonToggleElement = this.$el.querySelector<HTMLDivElement>(`.offering-option-value[data-offering-option-name="${offeringOption.name}"] .button-toggle-component`);

				if (offeringOptionButtonToggleElement) {
					const offeringOptionButtonToggleElementRect = offeringOptionButtonToggleElement.getBoundingClientRect();
					styles['--offering-option-value-height'] = `${offeringOptionButtonToggleElementRect.width}px`;
				}
			}

			return styles;
		};
	}

	protected get productOptions(): (FullOfferingOptionVirtualIntersectModel | FullOfferingOptionIntersectModel)[] {
		if (this.internalValue) {
			return AppDataModule.getFullOfferingOptionIntersectModels({
				offeringModels: this.fullOfferingModels as FullOfferingModels,
				variantid: (
					this.isVirtualOffering
						? this.internalValue.variantid
						: undefined
				),
			});
		}

		return [];
	}

	protected get productOptionValuesToShow(): (offeringOption: OfferingOptionsToolbarFullOfferingOptionModel) => OfferingOptionsToolbarProductOptionValueModels {
		return (offeringOption) => {
			const productOption = this.productOptions
				.find((productOptionItem) => productOptionItem.id === offeringOption.id) as OfferingOptionsToolbarProductOptionModel;

			return AppDataModule.getFullOfferingOptionValueIntersectModels(
				this.productOptions,
				productOption,
				this.internalValue?.id,
				(
					_productOptionValue,
					productOptionToFilter,
				) => {
					if (
						!this.filterTags
						|| this.filterTags.length === 0
					) {
						return true;
					}

					if (!productOptionToFilter.tag) {
						return false;
					}

					if (typeof this.filterTags[0] === 'string') {
						return (this.filterTags as string[]).includes(productOptionToFilter.tag);
					}

					if (typeof this.filterTags[0] === 'object') {
						return !!(this.filterTags as OfferingOptionsToolbarFilterTag[]).find((filterTag) => filterTag.tag === productOptionToFilter.tag);
					}

					return false;
				},
			);
		};
	}

	protected get productOptionValueVariant(): (offeringOption: OfferingOptionsToolbarFullOfferingOptionModel) => ButtonToggleComponentVariant {
		return (offeringOption) => {
			const productOptionValues = this.productOptionValuesToShow(offeringOption);
			const productOptionValuesHaveImages = productOptionValues.some((productOptionValue) => !!productOptionValue.image);

			if (productOptionValuesHaveImages) {
				if (offeringOption.tag === 'frame') {
					return 'big-filled';
				}

				return 'big';
			}

			return 'default';
		};
	}

	protected get selectedOptionsFiltered(): OfferingOptionsToolbarFullOfferingOptionModel[] {
		if (!this.internalValue.options) {
			return [];
		}

		if (!this.filterTags) {
			return this.internalValue.options;
		}

		const { filterTags } = this;
		const valueOptions = [...this.internalValue.options];

		valueOptions.sort(
			(a, b) => {
				if (!a.tag) {
					return 1;
				}
				if (!b.tag) {
					return -1;
				}

				let aTagIndex: number;
				let bTagIndex: number;

				if (typeof filterTags[0] === 'string') {
					aTagIndex = (filterTags as string[]).indexOf(a.tag);
					bTagIndex = (filterTags as string[]).indexOf(b.tag);
				} else {
					aTagIndex = (filterTags as OfferingOptionsToolbarFilterTag[]).findIndex((filterTag) => filterTag.tag === a.tag);
					bTagIndex = (filterTags as OfferingOptionsToolbarFilterTag[]).findIndex((filterTag) => filterTag.tag === b.tag);
				}

				if (aTagIndex === -1) {
					return 1;
				}
				if (bTagIndex === -1) {
					return -1;
				}

				return aTagIndex - bTagIndex;
			},
		);

		return valueOptions.reduce(
			(options, option) => {
				if (option.tag
					&& option.showduringedit
				) {
					if (
						typeof filterTags[0] === 'string'
						&& (filterTags as string[]).includes(option.tag)
					) {
						options.push(option);
					} else if (
						typeof filterTags[0] === 'object'
						&& (filterTags as OfferingOptionsToolbarFilterTag[]).find((filterTag) => filterTag.tag === option.tag)
					) {
						options.push(option);
					}
				}

				return options;
			},
			[] as OfferingOptionsToolbarFullOfferingOptionModel[],
		);
	}

	protected get shouldShowScrollButton(): (
		offeringOptionName: OfferingOptionsToolbarFullOfferingOptionModel['name'],
		scrollButton: OfferingOptionsToolbarScrollDirection,
	) => boolean {
		return (
			offeringOptionName,
			scrollButton,
		) => {
			if (!this.$el) {
				return false;
			}

			const offeringOptionValueButtonGroupElement = this.$el.querySelector<HTMLDivElement>(`.offering-option-value[data-offering-option-name="${offeringOptionName}"] .button-toggle-group-component`);

			if (!offeringOptionValueButtonGroupElement) {
				return false;
			}

			const isFullyVisibleResult = domUtils.isFullyVisible(offeringOptionValueButtonGroupElement);

			if (scrollButton === 'left') {
				return !isFullyVisibleResult.isLeftVisible;
			}

			return !isFullyVisibleResult.isRightVisible;
		};
	}

	protected get showPricing(): boolean {
		return !!this.internalValue.showPricing;
	}

	private internalValue: OfferingOptionsToolbarFullOfferingModel = {} as OfferingOptionsToolbarFullOfferingModel;

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	private isMouseDown?: boolean;

	private mouseDownInterval?: NodeJS.Timeout;

	private mouseDownTimeout?: NodeJS.Timeout;

	private resizeObserver?: ResizeObserver;

	private wasMouseDown?: boolean;

	protected beforeDestroy(): void {
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.isMobileUnwatch?.();
		this.resizeObserver?.disconnect();

		if (this.mouseDownInterval) {
			clearInterval(this.mouseDownInterval);
			this.mouseDownInterval = undefined;
		}

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
			this.mouseDownTimeout = undefined;
		}
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	protected mounted(): void {
		this.$forceCompute('offeringOptionStyles');
		this.resizeObserver = new ResizeObserver(() => {
			this.$forceCompute('offeringOptionStyles');
		});
		this.resizeObserver.observe(this.$el);
		this.onOfferingOptionValueScroll();
	}

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		if (this.internalValue.id !== this.value.id) {
			this.$emit(
				'change',
				this.internalValue,
			);
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.$nextTick(() => {
			this.internalValue = {
				...this.value,
			};
		});
	}

	protected getOfferingOptionName(offeringOption: OfferingOptionsToolbarFullOfferingOptionModel): string {
		return this.$t(
			`offeringOptions:${offeringOption.id}`,
			offeringOption.name,
		);
	}

	protected getOfferingOptionValueLabel(productOptionValue: OfferingOptionsToolbarProductOptionValueModel): string {
		if (productOptionValue.id) {
			return this.$t(
				`offeringOptionValues:${productOptionValue.id}`,
				productOptionValue.value,
			);
		}
		if (productOptionValue.offeringid) {
			return (
				this.fullOfferingModels.find((offeringModel) => offeringModel.id === productOptionValue.offeringid)?.size
				|| productOptionValue.value
			);
		}

		return productOptionValue.value;
	}

	protected onApplyClick(): void {
		this.$emit('apply');
		this.$emit('close');
	}

	protected onOfferingOptionChange(
		offeringOption: OfferingOptionsToolbarFullOfferingOptionModel,
		offerintOptionValue: OfferingOptionsToolbarProductOptionValueModel['id'] | OfferingOptionsToolbarProductOptionValueModel['offeringid'],
	): void {
		const newOfferingOption = this.productOptions
			.find((productOption) => {
				if (offeringOption.id) {
					return productOption.id === offeringOption.id;
				}
				if ('variantid' in productOption) {
					return productOption.variantid === offeringOption.variantid;
				}

				return false;
			}) as OfferingOptionsToolbarProductOptionModel;
		const newOfferingOptionValue = newOfferingOption.items
			.find((item) => {
				if (item.id) {
					return item.id === offerintOptionValue;
				}

				return item.offeringid === offerintOptionValue;
			}) as OfferingOptionsToolbarProductOptionValueModel;

		const matchingOfferingModel = AppDataModule.getMatchingFullOfferingModel({
			offeringOption: newOfferingOption,
			offeringOptionValue: newOfferingOptionValue,
			offeringModel: this.internalValue,
			offeringModels: this.fullOfferingModels,
		});

		if (matchingOfferingModel) {
			this.internalValue = matchingOfferingModel;
		}
	}

	private onOfferingOptionValueScroll(): void {
		requestAnimationFrame(() => {
			this.$forceCompute('shouldShowScrollButton');
		});
	}

	protected onScrollButtonClick(
		offeringOptionName: OfferingOptionsToolbarFullOfferingOptionModel['name'],
		scrollButton: OfferingOptionsToolbarScrollDirection,
	): void {
		if (this.wasMouseDown) {
			this.wasMouseDown = false;
			return;
		}

		const offeringOptionValueElement = this.$el.querySelector<HTMLDivElement>(`.offering-option-value[data-offering-option-name="${offeringOptionName}"]`);

		if (!offeringOptionValueElement) {
			return;
		}

		const offeringOptionValueElementWidth = offeringOptionValueElement.clientWidth;
		let offeringOptionValueElementScrollLeft = offeringOptionValueElement.scrollLeft;

		if (scrollButton === 'left') {
			offeringOptionValueElementScrollLeft -= offeringOptionValueElementWidth / 2;
		} else {
			offeringOptionValueElementScrollLeft += offeringOptionValueElementWidth / 2;
		}

		offeringOptionValueElement.scrollTo({
			behavior: 'smooth',
			left: offeringOptionValueElementScrollLeft,
		});
	}

	protected onScrollButtonMouseDown(
		offeringOptionName: OfferingOptionsToolbarFullOfferingOptionModel['name'],
		scrollButton: OfferingOptionsToolbarScrollDirection,
	): void {
		this.isMouseDown = true;
		window.addEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onWindowMouseUp,
		);

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
		}

		this.mouseDownTimeout = setTimeout(
			() => {
				if (this.mouseDownInterval) {
					clearInterval(this.mouseDownInterval);
				}

				this.mouseDownInterval = setInterval(
					() => {
						if (!this.isMouseDown) {
							clearInterval(this.mouseDownInterval);
							this.mouseDownInterval = undefined;
							return;
						}

						this.$emit('mousepress');
						const offeringOptionValueElement = this.$el.querySelector<HTMLDivElement>(`.offering-option-value[data-offering-option-name="${offeringOptionName}"]`);

						if (!offeringOptionValueElement) {
							return;
						}

						this.wasMouseDown = true;
						let offeringOptionValueElementScrollLeft = offeringOptionValueElement.scrollLeft;

						if (scrollButton === 'left') {
							offeringOptionValueElementScrollLeft -= 1;
						} else {
							offeringOptionValueElementScrollLeft += 1;
						}

						offeringOptionValueElement.scrollTo({
							left: offeringOptionValueElementScrollLeft,
						});
					},
					10,
				);
			},
			500,
		);
	}

	private onWindowMouseUp(): void {
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.isMouseDown = false;

		if (this.mouseDownInterval) {
			clearInterval(this.mouseDownInterval);
			this.mouseDownInterval = undefined;
		}

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
			this.mouseDownTimeout = undefined;
		}

		requestAnimationFrame(() => {
			this.wasMouseDown = false;
		});
	}
}
