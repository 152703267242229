import { LogoColorsResult } from 'interfaces/app';
import { OfferingModel } from 'interfaces/database';
import { PageObjectModel } from 'interfaces/project';

// eslint-disable-next-line import/prefer-default-export
export function check(
	offeringModel: OptionalExceptFor<OfferingModel, 'color'>,
	objectModel: OptionalExceptFor<PageObjectModel, 'colorReplacement'>,
): LogoColorsResult {
	const logoColors = new Set(
		objectModel?.colorReplacement
			?.map((vectorColor) => (
				vectorColor.replace?.real.toLocaleLowerCase()
				|| vectorColor.color.toLocaleLowerCase()
			))
			.filter((color) => color !== 'transparent'),
	).size;

	return {
		logoColors,
		offeringColors: offeringModel.color,
		valid: logoColors <= offeringModel.color,
	};
}
