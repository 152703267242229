import './defines';
import * as urlTools from '@sosocio/frontend-utils/url';
import {
	AbstractMesh,
	ArcRotateCamera,
	Color4,
	Engine,
	HDRCubeTexture,
	Nullable,
	PBRMaterial,
	Scene,
	SceneLoader,
	Texture,
	Vector3,
} from 'babylonjs';
import 'babylonjs-loaders';
import {
	EditorPreviewPageObjectModels,
	OfferingFrameModel,
} from 'interfaces/app';
import {
	Model3DModel,
	OfferingModel,
	PageModel,
} from 'interfaces/database';
import getCanvasSize from 'tools/get-canvas-size';
import EditorDrawView from 'views/editor-draw';
import {
	Component,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-property-decorator';
// @ts-ignore: special file format
import environmentFile from './environment.env';
import Template from './template.vue';

@Component({
	name: 'EditorPreview3DView',
	components: {
		EditorDrawView,
	},
})
export default class EditorPreview3DView extends Vue.extend(Template) {
	@Prop({
		default: 0,
		type: Number,
	})
	public readonly bleedMargin!: number;

	@Prop({
		default: undefined,
		schema: 'OfferingFrameModel',
		type: Object,
	})
	public readonly offeringFrameModel?: OfferingFrameModel;

	@Prop({
		required: true,
		schema: 'OfferingModel',
		type: Object,
	})
	public readonly offeringModel!: OfferingModel;

	@Prop({
		required: true,
		schema: 'PageModel',
		type: Object,
	})
	public readonly pageModel!: PageModel;

	@Prop({
		required: true,
		schema: 'EditorPreviewPageObjectModels',
		type: Array,
	})
	public readonly pageObjects!: EditorPreviewPageObjectModels;

	@Prop({
		required: true,
		schema: 'Model3DModel',
		type: Object,
	})
	public readonly value!: Model3DModel;

	protected get computedBleedMargin(): number {
		if (this.value.includeBleed) {
			return this.bleedMargin;
		}

		return 0;
	}

	protected get computedFullScaling(): number {
		const canvasHeight = Math.round(
			this.pageModel.height + (this.bleedMargin * 2),
		);
		const canvasWidth = Math.round(
			this.pageModel.width + (this.bleedMargin * 2),
		);

		const canvasSize = getCanvasSize(
			canvasWidth,
			canvasHeight,
		);

		return canvasSize.width / canvasWidth;
	}

	private get isReady(): boolean {
		return (
			this.previewReady
			&& this.sceneLoaded
		);
	}

	@Ref('canvas')
	private readonly canvasElement!: HTMLCanvasElement;

	private editorDrawCanvasElement?: HTMLCanvasElement;

	private engine!: Engine;

	private previewReady = false;

	private resizeObserver?: ResizeObserver;

	private resizing?: boolean;

	private scene!: Scene;

	private sceneLoaded = false;

	protected beforeDestroy(): void {
		this.engine?.dispose();
		this.resizeObserver?.disconnect();
	}

	protected mounted(): void {
		this.resizeObserver = new ResizeObserver(this.onResizeObserver);
		this.resizeObserver.observe(this.$el);
		this.loadScene();
	}

	@Watch('isReady')
	protected isReadyChange(): void {
		if (this.isReady) {
			this.scene.createDefaultCamera(
				true,
				true,
				true,
			);

			let camera: ArcRotateCamera | undefined;

			if (this.scene.activeCamera) {
				camera = this.scene.activeCamera as ArcRotateCamera;

				// Limit zoom speed when using scroll wheel on mouse
				camera.wheelPrecision = 200;
				camera.pinchPrecision = 500;
				camera.panningSensibility = 5000;

				// Let camera automatically rotate around object
				camera.useAutoRotationBehavior = Boolean(this.value.cameraAutoRotate);

				if (camera.autoRotationBehavior) {
					// Set auto rotation speed
					camera.autoRotationBehavior.idleRotationSpeed = this.value.cameraRotationSpeed;
				}
			}

			this.scene.clearColor = new Color4(
				0,
				0,
				0,
				0,
			);

			if (this.value.envTextureUrl) {
				const texture = new HDRCubeTexture(
					this.value.envTextureUrl,
					this.scene,
					64,
					false,
					true,
					false,
					true,
				);
				this.scene.environmentIntensity = this.value.envIntensity;
				this.scene.createDefaultEnvironment({
					createSkybox: false,
					createGround: false,
					environmentTexture: texture,
				});
			} else {
				this.scene.createDefaultEnvironment({
					createSkybox: false,
					createGround: false,
					environmentTexture: environmentFile,
				});

				if (this.value?.envIntensity) {
					this.scene.environmentIntensity = this.value.envIntensity;
				}
			}

			if (camera) {
				// Optionally tilt the camera to get more 3d perspective
				camera.alpha += this.value.cameraAlpha;
				camera.beta += this.value.cameraBeta;
				this.setZoomFactorAndZoomOn();
			}

			this.addTextureToModel();
			this.engine.runRenderLoop(() => {
				if (
					this.scene
					&& !this.scene.isDisposed
				) {
					this.scene.render();
				}
			});
		} else {
			this.loadScene();
		}
	}

	@Watch('value')
	protected onValueChange(): void {
		this.loadScene();
	}

	private addTextureToModel(): void {
		const { editorDrawCanvasElement } = this;

		if (
			editorDrawCanvasElement
			&& editorDrawCanvasElement.width > 0
		) {
			const renderedImage = editorDrawCanvasElement.toDataURL();
			const texture = new Texture(
				'data:pageImage',
				this.scene,
				false,
				false,
				Texture.BILINEAR_SAMPLINGMODE,
				null,
				null,
				renderedImage,
				true,
			);
			this.scene.addTexture(texture);

			if (this.value.printMaterialName) {
				const printArea = this.scene.getMaterialById<PBRMaterial>(this.value.printMaterialName);

				if (printArea) {
					if (this.value.transparencyMode) {
						printArea.transparencyMode = this.value.transparencyMode;
					}

					printArea.albedoTexture = texture;
				}
			}

			if (
				this.value.textureMaterialName
				&& this.value.textureUrl
			) {
				const textureArea = this.scene.getMaterialById<PBRMaterial>(this.value.textureMaterialName);

				if (textureArea) {
					const textureMaterialTexture = new Texture(
						this.value.textureUrl,
						this.scene,
						false,
						false,
						Texture.BILINEAR_SAMPLINGMODE,
						null,
						null,
						undefined,
						true,
					);
					this.scene.addTexture(textureMaterialTexture);
					textureArea.albedoTexture = textureMaterialTexture;
				}
			}
		}
	}

	private getBiggestMesh(): AbstractMesh | undefined {
		return this.scene?.meshes.reduce(
			(biggest, mesh) => {
				if (
					mesh.isVisible
					&& mesh.getTotalVertices() > 0
				) {
					const biggestMax = biggest.getBoundingInfo().boundingBox.maximumWorld;
					const meshMax = mesh.getBoundingInfo().boundingBox.maximumWorld;

					const maximize = Vector3.Maximize(
						biggestMax,
						meshMax,
					);

					if (maximize.equals(meshMax)) {
						return mesh;
					}
				}

				return biggest;
			},
			this.scene.meshes[0],
		);
	}

	private getTotalMeshesSize(): Vector3 {
		const getParentSize = (parent: AbstractMesh) => {
			const sizes = parent.getHierarchyBoundingVectors();

			return new Vector3(
				sizes.max.x - sizes.min.x,
				sizes.max.y - sizes.min.y,
				sizes.max.z - sizes.min.z,
			);
		};

		if (this.scene) {
			return getParentSize(this.scene.meshes[0]);
		}

		return new Vector3(
			0,
			0,
			0,
		);
	}

	private loadScene(): void {
		this.sceneLoaded = false;
		const rootElement = this.$el as HTMLDivElement;

		if (
			rootElement.clientHeight === 0
			|| rootElement.clientWidth === 0
		) {
			requestAnimationFrame(this.loadScene);
			return;
		}

		rootElement.style.maxHeight = '';
		rootElement.style.maxWidth = '';
		rootElement.style.maxHeight = `${rootElement.clientHeight}px`;
		rootElement.style.maxWidth = `${rootElement.clientWidth}px`;

		this.engine?.dispose();
		this.canvasElement.height = rootElement.clientHeight;
		this.canvasElement.width = rootElement.clientWidth;
		this.engine = new Engine(
			this.canvasElement,
			true,
		);
		/**
		 * Disable the babylon loading screen
		 */
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.engine.displayLoadingUI = () => { };
		this.scene?.dispose();
		this.scene = new Scene(this.engine);

		const parsedUrl = urlTools.parse(this.value.sceneUrl);
		const pathWithoutFileName = `${parsedUrl.path.substring(
			0,
			parsedUrl.path.lastIndexOf('/'),
		)}/`;
		const urlWithoutFileName = `${parsedUrl.protocol}//${parsedUrl.host}${pathWithoutFileName}`;

		SceneLoader
			.AppendAsync(
				urlWithoutFileName,
				parsedUrl.fileName,
				this.scene,
			)
			.then(() => {
				this.sceneLoaded = true;
			});
	}

	protected onCanvasDrawn(canvasElement: HTMLCanvasElement): void {
		if (canvasElement) {
			this.previewReady = false;
			this.editorDrawCanvasElement = canvasElement;

			if (
				this.previewReady
				&& this.value.printMaterialName
			) {
				const printArea = this.scene.getMaterialById<PBRMaterial>(this.value.printMaterialName);

				if (printArea) {
					// The page-image has been updated, so we need to refresh the material in the scene to reflect the changes
					printArea.albedoTexture?.dispose();
					this.addTextureToModel();
				}
			}

			this.previewReady = true;
		}
	}

	private onResizeObserver(): void {
		if (!this.resizing) {
			this.resizing = true;
			const rootElement = this.$el as HTMLDivElement;

			if (
				rootElement.clientHeight === 0
				|| rootElement.clientWidth === 0
			) {
				this.resizing = false;
				return;
			}

			requestAnimationFrame(() => {
				this.canvasElement.height = 10;
				this.canvasElement.width = 10;

				requestAnimationFrame(() => {
					rootElement.style.maxHeight = '';
					rootElement.style.maxWidth = '';
					rootElement.style.maxHeight = `${rootElement.clientHeight}px`;
					rootElement.style.maxWidth = `${rootElement.clientWidth}px`;
					this.canvasElement.height = rootElement.clientHeight;
					this.canvasElement.width = rootElement.clientWidth;
					this.engine?.resize();
					this.setZoomFactorAndZoomOn();

					requestAnimationFrame(() => {
						this.resizing = false;
					});
				});
			});
		}
	}

	private setZoomFactorAndZoomOn(): void {
		const camera = this.scene.activeCamera as Nullable<ArcRotateCamera>;
		const biggestMesh = this.getBiggestMesh();

		if (
			camera
			&& biggestMesh
		) {
			const biggestMeshSize = this.getTotalMeshesSize();
			const canvasIsLandscape = this.canvasElement.width > this.canvasElement.height;
			const meshIsLandscape = biggestMeshSize.x > biggestMeshSize.y;
			let meshAspectRatio = biggestMeshSize.x / biggestMeshSize.y;
			meshAspectRatio /= meshAspectRatio + 1;
			let smallCameraZoomOnFactor: number;
			let bigCameraZoomOnFactor: number;

			if (!canvasIsLandscape) {
				smallCameraZoomOnFactor = (
					meshIsLandscape
						? 1.70
						: 0.90
				);
				bigCameraZoomOnFactor = (
					meshIsLandscape
						? 0.10
						: 0.90
				);
			} else {
				smallCameraZoomOnFactor = (
					meshIsLandscape
						? 0.75
						: 1.75
				);
				bigCameraZoomOnFactor = (
					meshIsLandscape
						? 0.75
						: 0.75
				);
			}

			const blendFactor = 1 - Math.exp(-1 * meshAspectRatio); // Smooth transition curve
			camera.zoomOnFactor = (bigCameraZoomOnFactor * (1 - blendFactor)) + (smallCameraZoomOnFactor * blendFactor);
			camera.zoomOn(
				[biggestMesh],
				true,
			);
		}
	}
}
