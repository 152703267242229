import './defines';
import transparentSVG from '@root/img/colors/transparent.svg';
import ColorConverter from '@sosocio/color-converter';
import ButtonComponent from 'components/button';
import type DialogComponent from 'components/dialog';
import PantoneMatchingSystemHeaderComponent from 'components/pantone-matching-system-header';
import PmsColorPickerComponent from 'components/pms-color-picker';
import SwitchBoxComponent from 'components/switch-box';
import type TooltipComponent from 'components/tooltip';
import {
	EditorVectorEditColorsPhotoVectorColor,
	EditorVectorEditColorsPhotoVectorColors,
	PmsColorPickerMode,
} from 'interfaces/app';
import { PageObjectColorReplacementReplaceModel } from 'interfaces/project';
import { COLOR_FULL } from 'settings/offerings';
import { ConfigModule } from 'store';
import {
	logoColors as logoColorsTools,
	mobile as mobileTools,
} from 'tools';
import { type VueConstructor } from 'vue';
import {
	Component,
	Model,
	Prop,
	Vue,
	Watch,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorVectorEditColorsView',
	components: {
		ButtonComponent,
		SwitchBoxComponent,
	},
})
export default class EditorVectorEditColorsView extends Vue.extend(Template) {
	@Model(
		'change',
		{
			description: 'Defines the list of colors with their replacements for the photo vector',
			required: true,
			schema: 'EditorVectorEditColorsPhotoVectorColors',
			type: Array,
		},
	)
	public readonly value!: EditorVectorEditColorsPhotoVectorColors;

	@Prop({
		description: 'Defines the color limit for the photo vector',
		required: true,
		type: Number,
	})
	public readonly colorLimit!: number;

	protected get areColorsDifferentFromOriginals(): boolean {
		return this.internalValue.some((vectorColor) => {
			if (this.pmsPickerMode === 'full') {
				return (
					vectorColor.replace
					&& vectorColor.replace.real.toLowerCase() !== vectorColor.color
				);
			}

			const colorConverter = new ColorConverter();
			colorConverter.hex6 = {
				value: vectorColor.color.slice(1),
			};
			colorConverter.pantone = {
				name: colorConverter.pantone.name,
			};

			return `#${colorConverter.hex6.value}` !== vectorColor.replace?.real.toLowerCase();
		});
	}

	protected get isFullColor(): boolean {
		return this.colorLimit === COLOR_FULL;
	}

	protected get isSingleColor(): boolean {
		return new Set(
			this.internalValue.map((vectorColor) => (
				vectorColor.replace?.real
				|| vectorColor.color
			)),
		).size === 1;
	}

	protected get pmsColorPickerFromColors(): string[] {
		return this.internalValue.map((vectorColor) => {
			let { color } = vectorColor;

			if (this.pmsPickerMode !== 'full') {
				const colorConverter = new ColorConverter();
				colorConverter.hex6 = {
					value: color.slice(1),
				};
				colorConverter.pantone = {
					name: colorConverter.pantone.name,
				};

				if (!colorConverter.hex6.showAs) {
					color = `#${colorConverter.hex6.value}`;
				} else {
					color = `#${colorConverter.hex6.showAs}`;
				}
			}

			return color;
		});
	}

	protected get pmsColorPickerValue(): string | undefined {
		const internalValueColorFound = this.internalValue.find((vectorColor) => (
			vectorColor.color === this.selectedColor
			|| vectorColor.replace?.real === this.selectedColor
		));
		const colorToUse = (
			internalValueColorFound?.replace?.real
			|| internalValueColorFound?.color
		);

		if (
			internalValueColorFound
			&& colorToUse
			&& this.pmsPickerMode !== 'full'
			&& colorToUse.toLowerCase() !== 'transparent'
		) {
			const colorConverter = new ColorConverter();
			colorConverter.hex6 = {
				value: colorToUse.slice(1),
			};
			colorConverter.pantone = {
				name: colorConverter.pantone.name,
			};

			if (colorConverter.hex6.forceAs) {
				return `#${colorConverter.hex6.forceAs}`;
			}
		}

		return colorToUse;
	}

	protected get photoVectorColors(): string[] {
		return this.internalValue.map((vectorColor) => (
			vectorColor.replace?.visual
			|| vectorColor.replace?.real
			|| vectorColor.color
		));
	}

	protected get selectedOriginalColor(): string | null {
		if (this.selectedColor) {
			if (this.pmsPickerMode === 'full') {
				return this.selectedColor;
			}

			const colorConverter = new ColorConverter();
			colorConverter.hex6 = {
				value: this.selectedColor.slice(1),
			};
			colorConverter.pantone = {
				name: colorConverter.pantone.name,
			};

			return `#${(
				colorConverter.hex6.forceAs
				?? colorConverter.hex6.value
			)}`;
		}

		return null;
	}

	protected get showPantoneRegisteredMark(): boolean {
		return ConfigModule['features.pms'];
	}

	protected get totalColorCount(): number {
		return logoColorsTools
			.check(
				{
					color: this.colorLimit,
				},
				{
					colorReplacement: this.internalValue.map((vectorColor) => ({
						color: vectorColor.color,
						replace: (
							vectorColor.replace
								? vectorColor.replace
								: {
									real: vectorColor.color,
								}
						),
					})),
				},
			).logoColors;
	}

	protected imageUrlColorLimit = '';

	private internalValue: EditorVectorEditColorsPhotoVectorColors = [];

	private isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	private pmsColorPickerDialogTooltipClose?: () => void;

	private previousColorsBeforeConvertToSingleColor?: PageObjectColorReplacementReplaceModel[];

	protected selectedColor: string | null = null;

	private get pmsPickerMode(): PmsColorPickerMode {
		if (this.colorLimit !== COLOR_FULL) {
			return 'pms';
		}

		return 'full';
	}

	protected beforeDestroy(): void {
		this.isMobileUnwatch?.();
		this.pmsColorPickerDialogTooltipClose?.();
		this.pmsColorPickerDialogTooltipClose = undefined;
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	@Watch(
		'colorLimit',
		{
			immediate: true,
		},
	)
	protected onColorLimitChange(): void {
		if (this.colorLimit) {
			let colorLimitFilename = 'full';

			if (this.colorLimit === 1) {
				colorLimitFilename = 'one';
			} else if (this.colorLimit === 2) {
				colorLimitFilename = 'two';
			} else if (this.colorLimit === 3) {
				colorLimitFilename = 'three';
			} else if (this.colorLimit === 4) {
				colorLimitFilename = 'four';
			}

			import(/* webpackMode: "eager" */ `@root/img/colors/${colorLimitFilename}.svg`)
				.then((imageUrl) => {
					this.imageUrlColorLimit = imageUrl.default;
				});
		}
	}

	@Watch('isSingleColor')
	protected onIsSingleColorChange(): void {
		if (this.selectedColor) {
			this.selectedColor = this.internalValue[0].color;
		}
	}

	@Watch('selectedColor')
	protected onSelectedColorChange(): void {
		const { selectedColor } = this;
		this.pmsColorPickerDialogTooltipClose?.();

		if (selectedColor) {
			type BodyComponent = typeof PmsColorPickerComponent;
			const dialogTooltipBody: (
				DialogServiceOptionsBody<BodyComponent>
				| ToolbarServiceOptionsBody<BodyComponent>
			) = {
				component: PmsColorPickerComponent,
				props: {
					fromColors: this.pmsColorPickerFromColors,
					originalColor: this.selectedOriginalColor || undefined,
					pickerMode: this.pmsPickerMode,
					value: this.pmsColorPickerValue,
				},
				listeners: {
					change: this.onPmsColorChange,
				},
			};
			/* eslint-disable @typescript-eslint/indent */
			let pmsColorPickerDialogTooltipApi: NonVuePublicProps<
				DialogComponent<
					typeof PmsColorPickerComponent,
					VueConstructor,
					VueConstructor
				>
				| TooltipComponent<typeof PmsColorPickerComponent>
			>;
			/* eslint-enable @typescript-eslint/indent */

			if (!this.isMobile) {
				const tooltipAnchor = this.$el?.querySelector<HTMLButtonElement>(`.editor-vector-edit-colors-view-color[data-color="${selectedColor}"]`);

				if (!tooltipAnchor) {
					return;
				}

				const {
					api: pmsColorPickerTooltipApi,
					close: pmsColorPickerTooltipClose,
				} = this.$openTooltip({
					body: {
						component: PmsColorPickerComponent,
						props: {
							fromColors: this.pmsColorPickerFromColors,
							originalColor: this.selectedOriginalColor || undefined,
							pickerMode: this.pmsPickerMode,
							value: this.pmsColorPickerValue,
						},
						listeners: {
							change: this.onPmsColorChange,
						},
					},
					listeners: {
						close: () => {
							// eslint-disable-next-line @typescript-eslint/no-use-before-define
							pmsColorPickerValueUnwatch?.();
							this.pmsColorPickerDialogTooltipClose = undefined;

							if (this.selectedColor === selectedColor) {
								this.selectedColor = null;
							}
						},
					},
					anchor: tooltipAnchor,
					distance: 12,
					hasCloseButton: false,
					initialPosition: 'bottom left',
					isModal: false,
					noArrow: true,
					tooltipStyles: {
						border: '1px solid var(--neutral1-borders-and-stroke)',
						boxShadow: '0px 16px 24px 0px rgba(0, 0, 0, 0.16)',
					},
				});
				pmsColorPickerDialogTooltipApi = pmsColorPickerTooltipApi;
				this.pmsColorPickerDialogTooltipClose = pmsColorPickerTooltipClose;
			} else {
				const {
					api: pmsColorPickerDialogApi,
					close: pmsColorPickerDialogClose,
				} = this.$openDialogNew({
					header: (
						this.showPantoneRegisteredMark
							? {
								component: PantoneMatchingSystemHeaderComponent,
								listeners: {
									close: () => {
										this.pmsColorPickerDialogTooltipClose?.();
									},
								},
							}
							: {
								title: this.$t('components.pmsColorPicker.pickYourColor'),
								styles: {
									fontFamily: 'var(--font-family-demi-bold)',
									fontSize: 'var(--font-size-s)',
									fontWeight: 'var(--font-weight-demi-bold)',
								},
							}
					),
					body: dialogTooltipBody,
					listeners: {
						close: () => {
							// eslint-disable-next-line @typescript-eslint/no-use-before-define
							pmsColorPickerValueUnwatch?.();
							this.pmsColorPickerDialogTooltipClose = undefined;

							if (this.selectedColor === selectedColor) {
								this.selectedColor = null;
							}
						},
					},
					styles: {
						'--dialog-component-row-gap': '20px',
					},
				});
				pmsColorPickerDialogTooltipApi = pmsColorPickerDialogApi;
				this.pmsColorPickerDialogTooltipClose = pmsColorPickerDialogClose;
			}

			const pmsColorPickerValueUnwatch = this.$watch(
				'pmsColorPickerValue',
				() => {
					const pmsColorPickerComponent = pmsColorPickerDialogTooltipApi.bodyComponent();

					if (
						pmsColorPickerComponent
						&& this.pmsColorPickerValue
					) {
						pmsColorPickerComponent.value = this.pmsColorPickerValue;
					}
				},
			);
		}
	}

	@Watch(
		'value',
		{
			deep: true,
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = [
			...this.value,
		];
	}

	protected getVectorColorClass(vectorColor: EditorVectorEditColorsPhotoVectorColor): Record<string, boolean> {
		return {
			'editor-vector-edit-colors-view-color-selected': vectorColor.color === this.selectedColor,
		};
	}

	protected getVectorColorStyles(vectorColor: EditorVectorEditColorsPhotoVectorColor): Partial<CSSStyleDeclaration> & Record<string, string> {
		const colorReplace = (
			vectorColor.replace?.visual
			|| vectorColor.replace?.real
			|| vectorColor.color
		);
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};

		if (colorReplace.toLowerCase() !== 'transparent') {
			styles['--vector-color'] = colorReplace;
		} else {
			styles['--vector-color-image'] = `url("${transparentSVG}")`;
			styles.backgroundSize = '10px';
		}

		if (vectorColor.color === this.selectedColor) {
			if (colorReplace.toLowerCase() !== 'transparent') {
				styles['--editor-vector-colors-view-color-box-shadow-color'] = colorReplace;
			} else {
				styles['--editor-vector-colors-view-color-box-shadow-color'] = 'var(--neutral1-borders-and-stroke)';
			}
		}

		return styles;
	}

	protected onColorClick(vectorColor: EditorVectorEditColorsPhotoVectorColor): void {
		if (this.selectedColor !== vectorColor.color) {
			this.selectedColor = vectorColor.color;
		} else {
			this.selectedColor = null;
		}
	}

	protected onPmsColorChange(color: string): void {
		let colorsToUpdate: EditorVectorEditColorsPhotoVectorColors;

		if (!this.isSingleColor) {
			colorsToUpdate = this.internalValue.filter((vectorColor) => vectorColor.color === this.selectedColor);
		} else {
			colorsToUpdate = this.internalValue;
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const colorToUpdate of colorsToUpdate) {
			Vue.set(
				colorToUpdate,
				'replace',
				{
					real: color,
				},
			);

			if (
				this.pmsPickerMode === 'pms'
				&& color.toLowerCase() !== 'transparent'
			) {
				let normalizedColor = color;

				if (normalizedColor.at(0) === '#') {
					normalizedColor = normalizedColor.slice(1);
				}

				const colorConverter = new ColorConverter();
				colorConverter.hex6 = {
					value: normalizedColor,
				};
				colorConverter.pantone = {
					name: colorConverter.pantone.name,
				};

				if (
					colorConverter.pantone.forceAs
					&& colorConverter.hex6.value !== normalizedColor
					&& colorToUpdate.replace
				) {
					colorToUpdate.replace.real = `#${colorConverter.hex6.value}`;
				}

				if (
					colorConverter.pantone.showAs
					&& colorToUpdate.replace
				) {
					colorConverter.rgb = {
						...colorConverter.pantone.showAs,
					};
					colorToUpdate.replace.visual = `#${colorConverter.hex6.value}`;
				}
			}
		}

		this.$emit(
			'change',
			this.internalValue,
		);

		if (this.isMobile) {
			this.pmsColorPickerDialogTooltipClose?.();
		}
	}

	protected onResetColorsClick(): void {
		// eslint-disable-next-line no-restricted-syntax
		for (const vectorColor of this.internalValue) {
			if (this.pmsPickerMode === 'full') {
				Vue.delete(
					vectorColor,
					'replace',
				);
				// eslint-disable-next-line no-continue
				continue;
			}

			const colorConverter = new ColorConverter();
			colorConverter.hex6 = {
				value: vectorColor.color.slice(1),
			};
			colorConverter.pantone = {
				name: colorConverter.pantone.name,
			};
			Vue.set(
				vectorColor,
				'replace',
				{
					real: `#${colorConverter.hex6.value}`,
				},
			);

			if (
				colorConverter.hex6.showAs
				&& vectorColor.replace
			) {
				vectorColor.replace.visual = `#${colorConverter.hex6.showAs}`;
			}
		}

		this.$emit(
			'change',
			this.internalValue,
		);
	}

	protected onSingleColorChange(value: boolean): void {
		this.selectedColor = null;

		if (value) {
			this.previousColorsBeforeConvertToSingleColor = [
				...this.internalValue
					.map((vectorColor) => {
						if (vectorColor.replace) {
							return {
								...vectorColor.replace,
							};
						}

						return {
							real: vectorColor.color,
						};
					}),
			];
			const color = (
				this.internalValue[0].replace?.real
				|| this.internalValue[0].color
			);
			const visualColor = this.internalValue[0].replace?.visual;

			// eslint-disable-next-line no-restricted-syntax
			for (const vectorColor of this.internalValue) {
				Vue.set(
					vectorColor,
					'replace',
					{
						real: color,
					},
				);

				if (
					visualColor
					&& vectorColor.replace
				) {
					vectorColor.replace.visual = visualColor;
				}
			}
		} else {
			for (let index = 1; index < this.internalValue.length; index += 1) {
				Vue.set(
					this.internalValue[index],
					'replace',
					(
						this.previousColorsBeforeConvertToSingleColor?.[index]
						?? {
							real: this.internalValue[index].color,
						}
					),
				);
			}

			this.previousColorsBeforeConvertToSingleColor = undefined;
		}

		this.$emit(
			'change',
			this.internalValue,
		);
	}
}
