import flagsmith from 'flagsmith';
import { IFlagsmithFeature } from 'flagsmith/types';
import { ConfigModule, UserModule } from 'store/index';
import analytics from 'controllers/analytics';

// Important: FlagSmith wants flags to be all lowercase
type Flags = {
	flag_force_color_limitation?: IFlagsmithFeature['value'];
	flag_dynamic_photo_scaling?: IFlagsmithFeature['value'];
	flag_face_detection?: IFlagsmithFeature['value'];
	flag_facebook_integration?: IFlagsmithFeature['value'];
	flag_google_integration?: IFlagsmithFeature['value'];
	flag_offering_conversion?: IFlagsmithFeature['value'];
	flag_show_new_editor?: IFlagsmithFeature['value'];
}
type FlagKeys = keyof Flags;

class ExperimentController {
	public init(): Promise<void> {
		if (ConfigModule['flagsmith.enabled']
			&& ConfigModule['flagsmith.id']
		) {
			return flagsmith.init({
				cacheFlags: true,
				environmentID: ConfigModule['flagsmith.id'],
				realtime: true,
				enableAnalytics: true,
				onChange: (oldFlags) => {
					if (oldFlags) {
						const changes: Record<string, string | number | boolean | null> = {};
						Object.entries(oldFlags).forEach(([flagName, oldFlagFeature]) => {
							const currentFlagValue = this.getFlagValue(flagName as keyof Flags);
							if (typeof currentFlagValue !== 'undefined'
								&& currentFlagValue !== oldFlagFeature.value
							) {
								changes[flagName] = currentFlagValue;
							}
						});

						if (Object.keys(changes).length > 0) {
							analytics.setExperimentFlags(
								changes,
							);
						}
					}
				},
			})
				.then(() => this.setUserTraits())
				.then(() => {
					const activeFlags = this.getActiveFlags();
					if (activeFlags) {
						analytics.setExperimentFlags(
							activeFlags,
						);
					}
				});
		}

		return Promise.resolve();
	}

	private getActiveFlags(): Flags | undefined {
		if (ConfigModule['flagsmith.enabled']) {
			const allFlags = flagsmith.getAllFlags();
			const flagNames = Object.keys(
				allFlags,
			) as Array<keyof typeof activeFlags>;

			const activeFlags: Partial<Flags> = {};

			flagNames.forEach((key) => {
				if (allFlags[key].enabled) {
					activeFlags[key] = allFlags[key].value;
				}
			});

			return activeFlags;
		}

		return undefined;
	}

	public getFlagValue(
		name: FlagKeys,
	): IFlagsmithFeature['value'] {
		if (ConfigModule['flagsmith.enabled']) {
			if (flagsmith.hasFeature(name)) {
				return flagsmith.getValue(name);
			}

			return undefined;
		}

		return undefined;
	}

	public setUserTraits(): Promise<void> {
		if (!UserModule.id) {
			return Promise.resolve();
		}

		if (ConfigModule['flagsmith.enabled']) {
			return flagsmith.identify(
				UserModule.id.toString(),
			);
		}

		return Promise.resolve();
	}
}

export default new ExperimentController();
